import { useState, useEffect } from 'react';

import loadable from '@loadable/component';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import ChevronDown from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-down';
import ChevronUp from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-up';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import type { InfoBlockFreeformProps } from 'common-types/types/InfoBlockFreeform';

import STYLES from './InfoBlockFreeform.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const ChevronDownArrow = withButtonAlignment(withRtlSupport(ChevronDown));
const ChevronUpArrow = withButtonAlignment(withRtlSupport(ChevronUp));
const MAX_LINE_CLAMP = 5;

type RleState = {
  clamped: boolean;
} | null;

const InfoBlockFreeform = ({
  strings,
  subtitle,
  text,
  title,
}: InfoBlockFreeformProps) => {
  const [expanded, setExpanded] = useState(false);

  const [showLessOrMore, setShowLessOrMore] = useState(true);

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.INFO_BLOCK_FREEFORM,
      load_status: LOAD_STATUS.LOADED,
    });
  }, []);

  const handleReflow = (rleState: RleState) => {
    if (!rleState?.clamped) {
      setShowLessOrMore(false);
    }
  };

  const onClick = () => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.INFO_BLOCK_FREEFORM,
      component_action: COMPONENT_ACTION.EXPANDABLE_TEXT.READ_MORE_LESS_CLICKED,
    });
    setExpanded(!expanded);
  };

  return (
    <div className={STYLES.InfoBlockFreeform}>
      {!!title && (
        <div className={STYLES.InfoBlockFreeform__header}>
          <BpkSectionHeader title={title} description={subtitle || ''} />
        </div>
      )}
      {expanded ? (
        <BpkText
          dangerouslySetInnerHTML={{
            __html: text,
          }}
          bareHtml
        />
      ) : (
        <BpkText>
          <HTMLEllipsis
            unsafeHTML={text}
            maxLine={MAX_LINE_CLAMP}
            ellipsis="..."
            basedOn="letters"
            onReflow={handleReflow}
          />
        </BpkText>
      )}
      {showLessOrMore && (
        <div className={STYLES.InfoBlockFreeform__moreOrLess}>
          <BpkButton link onClick={() => onClick()}>
            <BpkText textStyle={TEXT_STYLES.label1}>
              {!expanded ? strings.readMore : strings.readLess}
            </BpkText>
            <span className={STYLES.InfoBlockFreeform__arrow}>
              {!expanded ? <ChevronDownArrow /> : <ChevronUpArrow />}
            </span>
          </BpkButton>
        </div>
      )}
    </div>
  );
};

export default (props: InfoBlockFreeformProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.EXPANDABLE_TEXT,
      })
    }
  >
    <InfoBlockFreeform {...props} />
  </IntersectionObserverWrapper>
);
